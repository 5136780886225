/* eslint-disable no-undef */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.previousPath = prevLocation ? prevLocation.pathname : null;
    location.state.previousHostname = prevLocation ? prevLocation.hostname : null;
  }
  window.locations = window.locations || [document.referrer];
  if (window.locations[locations.length - 1] !== window.location.href) {
    window.locations.push(window.location.href);
  }
  window.previousPath = window.locations[locations.length - 2];
};
